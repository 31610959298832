import React from "react";
import { graphql } from 'gatsby';

import Layout from '../components/layout'

const divStyle = {marginTop: "10px"};

const GoalsPage = ({ data }) => (
  <Layout>
    <h1>Goals for next year:</h1>{data.allNodeGoals.edges.map(({ node }) => (<div style={divStyle}> <h3>{node.title}</h3> <div dangerouslySetInnerHTML={{ __html: node.body.value }} /> </div>))}
  </Layout>
);
export const query = graphql` query allNodeGoals {
  allNodeGoals {
    edges {
      node {
        id
        title
        body {
          value
          format
          processed
          summary
        }
      }
    }
  }
} `;
export default GoalsPage;
